<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <loader
      :loaderOn="$store.state.loader.loaderOn"
      :linearLoader="$store.state.loader.linearLoader"
      :circleLoader="$store.state.loader.circleLoader"
    ></loader>
    <snackbar
      :timeout="$store.state.snack.timeout"
      :snackMessage="$store.state.snack.snackMessage"
      :typeSnack="$store.state.snack.typeSnack"
    ></snackbar>
    <confirm-message
      :message="$store.state.confirmMessage.message"
      :title="$store.state.confirmMessage.title"
      :type="$store.state.confirmMessage.type"
      :btConfirm="$store.state.confirmMessage.btConfirm"
      :btCancel="$store.state.confirmMessage.btCancel"
      :callback="$store.state.confirmMessage.callback"
    ></confirm-message>
  </v-app>
</template>

<script>
import loader from "./components/common/Loader-page";
import snackbar from "./components/common/Alerts-messages";
import confirmMessage from "./components/common/Confirm-message";
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // console.log('1', to, '2', from);
        // document.title = to.meta.title || "Some Default Title";
        if (to && to.path == "/portal-cliente") {
          document.title = "CORPe Saúde | Área do cliente";
        }
        if (to && to.path == "/portal-corretor") {
          document.title = "CORPe Saúde | Área do corretor";
        }
        if (to && to.path == "/") {
          document.title = "CORPe Saúde | Sua gestora de saúde";
        }
      },
    },
    isMobile(v) {
      //console.log(v);
      if (v == "sm" || v == "xs") {
        this.$store.commit("updateGeneralScreenType", true);
      } else {
        this.$store.commit("updateGeneralScreenType", false);
      }
      console.log("tela watch", this.$store.state.mobileMode);
    },
  },
  components: {
    loader,
    snackbar,
    confirmMessage,
  },
  mounted() {
    //console.log(`start`);
    //corporeadministradora
    if (window.location.href.indexOf("corporeadministradora") > -1) {
      let newUrl = window.location.href.replace("corporeadministradora", "corpesaude");
      window.location = newUrl;
    }

    document.title = "CORPe Saúde | Sua gestora de saúde";
    let secreenSize = this.$vuetify.breakpoint.name;
    if (secreenSize == "sm" || secreenSize == "xs") {
      this.$store.commit("updateGeneralScreenType", true);
    }
    setTimeout(() => {
      console.log("tela", this.$store.state.mobileMode);
    }, 10);
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name;
    },
  },
};
</script>
  <style>
/* @import './css/app.css'; */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.btMicro {
  min-width: auto !important;
  max-width: 36px !important;
}
.v-tooltip__content span {
  font-size: 12px !important;
}
.bt100 {
  width: 100%;
}
.forceLeftCbo .v-list-item__title {
  text-align: left !important;
}
</style>
