import Vue from 'vue'
import Vuex from 'vuex'
import confirmMessage from './store/confirm-message'
import formRules from './store/form-rules'
import request from './store/request'
// import genericFilters from './store/genericFilters'

import router from '../src/router/index'
Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        router,
        confirmMessage,
        formRules,
        request,
    },
    state: {
        mobileMode: false,
        clientLogged: false,
        config: {
            logo: {
                src: '',
                title: 'Marketplace'
            }
        },
        userLoged: {},
        loader: {
            loaderOn: false,
            linearLoader: true,
            circleLoader: false
        },
        snack: {
            snackbarShow: false,
            snackMessage: '',
            timeout: null,
            typeSnack: ''
        }
    },
    actions: {
        setCookie(state, obj) {
            const d = new Date();
            let mlseconds = obj.exdays * 24 * 60 * 60 * 1000;
            if (obj.flashCookie) {
                mlseconds = mlseconds / obj.flashCookie
            }
            d.setTime(d.getTime() + (mlseconds));
            let expires = "expires=" + d.toUTCString();
            console.log(expires)
            document.cookie = obj.cname + "=" + obj.cvalue + ";" + expires + ";path=/";
        },
        getCookie(state, cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    },
    mutations: {
        updateGeneralScreenType(state, type) {
            state.mobileMode = type
        },
        setClientLogged(state, status) {
            state.clientLogged = status
        },
        updateUserLoged: (state, obj) => {
            state.userLoged = obj
        },
        showLoader: (state, opts) => {
            if (opts && opts.circle) {
                state.loader.linearLoader = false
                state.loader.circleLoader = true
            } else {
                state.loader.linearLoader = true
                state.loader.circleLoader = false
            }
            state.loader.loaderOn = true
        },
        hideLoader: state => (state.loader.loaderOn = false),
        showAlert: (state, opts) => {
            state.snack.snackbarShow = true
            state.snack.snackMessage = opts.message
            state.snack.timeout = opts.time
            state.snack.typeSnack = opts.type
        }
    }
})