import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                vermelho_corpore: '#BD2730',
                cinza_corpore: '#676C72',
                preto: '#000000',
                azul: '#65acc2'
            },
        },
    },
})