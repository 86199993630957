<template>
  <v-snackbar
    rounded="pill"
    light
    v-model="$store.state.snack.snackbarShow"
    :multi-line="false"
    color="success"
    outlined
    :timeout="timeout ? timeout : 5000"
    :top="y"
    :vertical="mode === 'vertical'"
    class="snackCard"
  >
    <v-layout row wrap>
      <v-icon :color="typeSnack" class="ml-2 md1 snackIcons">{{
        icons[typeSnack]
      }}</v-icon>
      <div
        v-html="snackMessage"
        :class="['snackMessage md10 ml-2 mr-4 pr-2', 'grey--text darken-4']"
      >
        {{ snackMessage }}
      </div>
      <!-- <v-btn
        x-small
        icon
        fab
        class="m-0 closeSnack md1"
        color="blue-grey lighten-2"
        @click="$store.state.snack.snackbarShow = false"
      >
        <v-icon style="font-size: 16px">close</v-icon>
      </v-btn> -->
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    timeout: Number,
    snackMessage: String,
    typeSnack: String,
  },
  mounted() {},
  data() {
    return {
      icons: {
        error: "block",
        warning: "warning",
        info: "help",
        success: "check_circle",
      },
      mode: "",
      y: "top",
      x: null,
    };
  },
  methods: {
    showSnackBar() {
      this.snackbar = true;
    },
  },
};
</script>

<style>
.closeSnack,
.closeSnack:hover {
  height: 100% !important;
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 0 !important;
  outline: none !important;
  border-radius: 0;
  box-shadow: none !important;
  border-radius: 0px 2px 2px 0px !important;
}
.snackCard .v-snack__content {
  position: relative;
  padding-left: 15px;
}
.snackMessage {
  max-width: 350px;
  margin-top: 2px !important;
}
.v-snack__wrapper {
  border-radius: 0 0 3px 2px !important;
  min-width: 270px !important;
}
</style>
