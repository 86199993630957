<template>
  <v-dialog
    light
    scrollable
    v-model="$store.state.confirmMessage.confirmDialog"
    persistent
    max-width="350"
  >
    <v-card>
      <v-card-title class="title pb-0">
        <v-icon class="pr-2" :color="type">{{ icons[type] }}</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text class="pl-7 pt-2 subheading confirmMesgTxt text-sm-left"
        ><div v-html="message"></div
      ></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-show="btCancel.show"
          color="error darken-1"
          plain
          @click="$store.commit('confirmMessage/closeConfirmMessage')"
          >{{ btCancel.name }}</v-btn
        >
        <v-btn
          v-show="btConfirm.show"
          color="green darken-1"
          plain
          @click="doCallback"
          >{{ btConfirm.name }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    message: String,
    title: String,
    type: String,
    callback: Function,
    btConfirm: Object,
    btCancel: Object,
  },
  mounted() {},
  methods: {
    doCallback() {
      if (this.callback) {
        this.callback();
        //this.$store.commit('confirmMessage/closeConfirmMessage')
      }
      this.$store.commit("confirmMessage/closeConfirmMessage");
    },
  },
  data() {
    return {
      icons: {
        error: "block",
        warning: "warning",
        info: "help",
        success: "check_circle",
      },
    };
  },
};
</script>

<style>
.confirmMesgTxt {
  border-bottom: 1px solid #eee;
}
</style>
